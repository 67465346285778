import React from 'react';

const Benefits = () => (
  <div className="v-home-benefits with-top-decoration with-bottom-decoration">
    <ul className="c-benefits">
      <li className="benefit_search">
        <h3>Largest availability of parts</h3>
        <p>
          In partnership with our distributors, we have the largest availability of
          parts in the industry.
        </p>
      </li>
      <li className="benefit_ease">
        <h3>Quick Turn PCB Quoting</h3>
        <p>
          Take advantage of ADCOproto’s dynamic supply chain PCB capabilities.
        </p>
      </li>
      <li className="benefit_cost">
        <h3>Transparent costs</h3>
        <p>
          We have the parts, quote materials, quote boards and PCB assembly costs instantly.
        </p>
      </li>
      <li className="benefit_quality">
        <h3>First-class quality</h3>
        <p>
          World class electronic PCB assembly capability to get it right the
          first time.
        </p>
      </li>
      <li className="benefit_delivery">
        <h3>Easy ordering process, free and quick delivery</h3>
        <p>
        Get your quote ready in a matter of minutes and get delivered in 5, 10 or 15 days
        for free with UPS Ground.
        </p>
      </li>
      <li className="benefit_bomtool">
        <h3>Interactive BOM tool</h3>
        <p>
          We find availability and price of your parts. You can use real-time search to find
          alternatives and save the BOM for later.
        </p>
      </li>
    </ul>
  </div>
);

export default Benefits;
