import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { pages } from '@src/constants/pages';

import ill1 from '@src/images/ill1.svg';

const Quoting = () => (
  <div className="v-home-section h-pt-60">
    <aside>
      <LazyLoadImage effect="blur" src={ill1} alt="Online quoting illustration" />
    </aside>
    <div className="v-home-section--right">
      <h3 className="h-mb-30">How does this turnkey quoting system work?</h3>
      <p>
        We have focused on the Bill of Materials as the key element in making a
        rapid delivery of circuitboard assemblies. Our application makes API
        calls to six component distributors to locate parts that are available
        and then selects the lowest price all done in real time. That said, it
        is looking for literal/exact matches to the BOM specification.
      </p>
      <p>
        The impact of this is a need for clarification regarding &quot;inexact
        matches&quot;. Our application will prompt you to make the necessary changes
        to your Bill of Materials to solve any issues. It will make multiple
        online searches as you develop possible supply alternatives. The end
        result is a Scrubbed and costed BOM showing any corrections needed to
        guarantee component availability.
      </p>
      <Link className="with-arrow" to={pages.demo.path}>See how it works</Link>
    </div>
  </div>
);

export default Quoting;
