import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import * as ConfigValues from '@src/helpers/configValues';
import { pages } from '@src/constants/pages';
import { events } from '@src/constants/gaEvents';
import { customEvent } from '@src/helpers/gtag';

import AccountInfoModal from '@src/components/accountInfoModal';

const Banner = ({ thumbnailData }) => {
  const [player, setPlayer] = useState(null);
  const [isPlayPending, setIsPlayPending] = useState(false);
  const [isStartup, setIsStartup] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handleVideoReady = useCallback(event => {
    setPlayer(event.target);
    if (isPlayPending) {
      event.target.playVideo();
    }
  });

  const startVideo = useCallback(() => {
    setIsStartup(false);
    setIsEnd(false);
    if (player) {
      setIsPlayPending(false);
      player.playVideo();
    } else {
      setIsPlayPending(true);
    }
  }, [player]);

  const handleVideoEnd = useCallback(() => {
    customEvent(events.homeBannerEnd())(); // analytics
    setIsEnd(true);
  });

  const [isAccountInfoModalOpen, setIsAccountInfoModalOpen] = useState(false);
  const openAccountInfoModal = () => setIsAccountInfoModalOpen(true);
  const closeAccountInfoModal = () => setIsAccountInfoModalOpen(false);

  return (
    <div className="v-home-intro">
      <section>
        <div>
          <h1 className="h-mb-40">
            Get an Instant Price Quote
            <br />
            <span>for your</span>
            <br />
            <div className="h-bold">Custom PCB Assembly Prototype</div>
          </h1>
          <div className="c-btn-group">
            <div className="h-flex-column">
              <a
                className="c-btn c-btn--primary--accent"
                href={ConfigValues.getAppLoginUrl()}
                onClick={customEvent(events.homeBannerCreateAccount())}
              >
                Log in / Create an account
              </a>
              <button className="c-link c-link--secondary" type="button" onClick={openAccountInfoModal}>
                Why create an account?
              </button>
            </div>

            <a
              className="c-btn c-btn--secondary h-ml-40"
              href={ConfigValues.getAppUrl()}
              onClick={customEvent(events.homeBannerStartQuoting())}
            >
              Continue as a guest
            </a>
          </div>

        </div>
        <div className="v-home-video">
          <div
            className={`v-home-video-thumbnail ${isStartup ? '' : 'h-hidden'}`}
            onClick={startVideo}
          >
            <Img fluid={thumbnailData} />
            <span className="v-home-video-play-btn" />
          </div>
          <div
            className={`v-home-video-thumbnail ${isEnd ? '' : 'h-hidden'}`}
            onClick={startVideo}
          >
            <div className="v-home-video-actions">
              <h4>Do you want to learn more?</h4>
              <p>
                Find out more about our services and how our online quoting tool works<br />
                <Link className="with-arrow" to={pages.demo.path}>See how it works</Link>
              </p>
              <span className="v-home-video-replay-btn" />
            </div>
          </div>
          <div className={isStartup || isEnd ? 'h-hidden' : ''}>
            {!isStartup && (
              <YouTube
                containerClassName="v-home-video-container"
                videoId="CaU7bEfx5tU"
                onReady={handleVideoReady}
                onPlay={customEvent(events.homeBannerPlay())}
                onEnd={handleVideoEnd}
                opts={{
                  height: '390',
                  width: '640',
                  frameBorder: '0',
                  playerVars: {
                    fs: 0,
                  },
                }}
              />
            )}
          </div>
        </div>
      </section>
      <h3 className="h-center h-mb-40">Parts supplied by</h3>
      <ul className="c-distributors">
        <li className="distributor-avnet">
          <a
            href="https://www.avnet.com/"
            target="_blank"
            rel="noopener noreferrer"
          />
        </li>
        <li className="distributor-future">
          <a
            href="https://www.futureelectronics.com/"
            target="_blank"
            rel="noopener noreferrer"
          />
        </li>
        <li className="distributor-digi-key">
          <a
            href="https://www.digikey.com/"
            target="_blank"
            rel="noopener noreferrer"
          />
        </li>
        <li className="distributor-tti">
          <a
            href="https://www.ttiinc.com/"
            target="_blank"
            rel="noopener noreferrer"
          />
        </li>
        <li className="distributor-newark">
          <a
            href="https://www.newark.com/"
            target="_blank"
            rel="noopener noreferrer"
          />
        </li>
        <li className="distributor-mouser">
          <a
            href="https://www.mouser.com/"
            target="_blank"
            rel="noopener noreferrer"
          />
        </li>
        <li className="distributor-arrow">
          <a
            href="https://www.arrow.com/"
            target="_blank"
            rel="noopener noreferrer"
          />
        </li>
      </ul>
      {isAccountInfoModalOpen && <AccountInfoModal isOpen={isAccountInfoModalOpen} onClose={closeAccountInfoModal} />}
    </div>
  );
};

Banner.propTypes = {
  thumbnailData: PropTypes.object,
};

export default Banner;
