import React from 'react';
import PropTypes from 'prop-types';
// components
import Modal from '@src/components/modal';
import ModalHeader from '@src/components/modalHeader';

const AccountInfoModal = ({ isOpen, onClose }) => (
  <Modal
    className="c-modal-medium"
    isOpen={isOpen}
    shouldCloseOnOverlayClick={true}
    shouldCloseOnEsc={true}
    onRequestClose={onClose}
  >
    <ModalHeader title="ADCOProto Account Benefits" onCloseClick={onClose} />
    <div className="c-modal-content">
      <ul className="c-list c-list--checkmarks">
        <li>Create, save and manage all your prototype quotes in one place</li>
        <li>Create as many quotes as you like!</li>
        <li>Save time when reordering your prototype assemblies.</li>
        <li>Save your work in progress. Come back to it later.</li>
        <li>Share quotes with teammates.</li>
        <li>Print your quotes.</li>
        <li>Save supplemental documentation.</li>
      </ul>
      <h4 className="h-center h-mb-0">AND IT&lsquo;S COMPLETELY FREE.</h4>
    </div>
    <div className="c-modal-buttons">
      <button
        className="c-btn c-btn--primary"
        onClick={onClose}
        type="button"
      >
        OK
      </button>
    </div>
  </Modal>
);

AccountInfoModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AccountInfoModal;
