import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import gallery4 from '@src/images/gallery4.jpg';
import gallery5 from '@src/images/gallery5.jpg';
import gallery7 from '@src/images/gallery7.jpg';
import gallery9 from '@src/images/gallery9.jpg';
import gallery10 from '@src/images/gallery10.jpg';
import gallery11 from '@src/images/gallery11.jpg';
import gallery12 from '@src/images/gallery12.jpg';
import gallery17 from '@src/images/gallery17.jpg';
import gallery29 from '@src/images/gallery29.jpg';

const Gallery = () => (
  <div className="c-gallery">
    <div>
      <LazyLoadImage effect="blur" src={gallery7} alt="Production line" />
    </div>
    <div className="tall">
      <LazyLoadImage effect="blur" src={gallery17} alt="Mounting" />
    </div>
    <div className="large">
      <LazyLoadImage effect="blur" src={gallery4} alt="PCB part detail" />
    </div>
    <div>
      <LazyLoadImage effect="blur" src={gallery10} alt="PCB testing" />
    </div>
    <div>
      <LazyLoadImage effect="blur" src={gallery12} alt="PCB" />
    </div>
    <div>
      <LazyLoadImage effect="blur" src={gallery5} alt="Printed circuit boards" />
    </div>
    <div>
      <LazyLoadImage effect="blur" src={gallery29} alt="PCB" />
    </div>
    <div className="large">
      <LazyLoadImage effect="blur" src={gallery9} alt="PCB part detail" />
    </div>
    <div>
      <LazyLoadImage effect="blur" src={gallery11} alt="PCB" />
    </div>
  </div>
);

export default Gallery;
