import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { pages } from '@src/constants/pages';

import ill2 from '@src/images/ill2.svg';
import img_ipc from '@src/images/icons/IPC.png';
import img_smta from '@src/images/icons/SMTA.png';
import img_rohs from '@src/images/icons/RoHS.png';
import img_itar from '@src/images/icons/ITAR.png';

const Manufacturing = () => (
  <div className="v-home-section">
    <div className="v-home-section--left">
      <h3 className="h-mb-30">Our manufacturing process meets the highest standard</h3>
      <p>
        ADCOproto customers get the benefit of working with an experienced, reliable,
        electronics manufacturing services company.  With over 40 years of company
        history serving a highly sophisticated customer base, we guarantee our workmanship.
      </p>
      <ul className="v-home-section-logos">
        <li><LazyLoadImage src={img_ipc} alt="ipc" /></li>
        <li><LazyLoadImage src={img_smta} alt="smta"  /></li>
        <li><LazyLoadImage src={img_rohs} alt="rohs"  /></li>
        <li><LazyLoadImage src={img_itar} alt="itar"  /></li>
      </ul>
    </div>
    <aside>
      <LazyLoadImage effect="blur" src={ill2} alt="Process illustration" />
    </aside>
  </div>
);

export default Manufacturing;
