import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '@src/components/layout';
import Banner from '@src/components/index/banner';
import Benefits from '@src/components/index/benefits';
import Gallery from '@src/components/index/gallery';
import Manufacturing from '@src/components/index/manufacturing';
import Quoting from '@src/components/index/quoting';
import Challenge from '@src/components/shared/challenge';
import { pages } from '@src/constants/pages';
import { events } from '@src/constants/gaEvents';
import { customEvent } from '@src/helpers/gtag';

import 'react-lazy-load-image-component/src/effects/blur.css';

const Index = ({ data }) => (
  <Layout pageName={pages.home.name}>
    <Banner thumbnailData={data.filename.childImageSharp.fluid} />
    <Benefits />
    <Quoting />
    <Manufacturing />
    <Gallery />
    <Challenge
      onStartQuotingClick={customEvent(events.homeStartQuoting())}
      onContactUsClick={customEvent(events.homeContactUs())}
    />
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object,
};

export default Index;

export const pageQuery = graphql`
  query HeaderImageQuery {
    filename: file(relativePath: { eq: "thumbnail.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
